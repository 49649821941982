require(['jquery', 'js/glider-min'], function ($) {
const contentSliders = document.querySelectorAll(".js-content-slider");

if (contentSliders) {
	contentSliders.forEach(function (contentSlider) {
		let slider = contentSlider.querySelector('.slides'),
			slidesToShow = slider.getAttribute('data-slides-to-show'),
			sliderPrev = contentSlider.querySelector('.js-content-slider-prev'),
			sliderNext = contentSlider.querySelector('.js-content-slider-next');

		new Glider(slider, {
			slidesToShow: 1.3,
			slidesToScroll: 1,
			draggable: true,
			arrows: {
				prev: sliderPrev,
				next: sliderNext
			},
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2.3,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: slidesToShow,
					}
				},
			]
		});
	})
}
});
